// Styles

// Main styles
@import "./app.scss";

// Socio Torcedor Icons
@import "./icons.scss";

.multiselect-dropdown .dropdown-btn {
  border-color: #ced4da !important;

  .dropdown-multiselect__caret:before {
    border-color: #8C97A1 transparent !important;
  }
}

