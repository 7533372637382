//
// forms.scss
//

.form-control,
.form-select {
    box-shadow: $components-shadow-sm;
}

// Form-control light
.form-control-light {
    background-color: $input-light-bg !important;
    border-color: $input-light-bg !important;
}

// Form elements (Color and Range)
input.form-control[type="color"],
input.form-control[type="range"] {
    min-height: 39px;
}

// Custom select
.custom-select.is-invalid,
.form-control.is-invalid,
.custom-select:invalid,
.form-control:invalid,
.custom-select.is-valid,
.form-control.is-valid,
.custom-select:valid,
.form-control:valid {
    &:focus {
        box-shadow: none !important;
    }
}

// Comment box
.comment-area-box {
    .form-control {
        border-color: #{map-get($grays, "300")};
        border-radius: $input-border-radius $input-border-radius 0 0;
    }
    .comment-area-btn {
        background-color: #{map-get($grays, "100")};
        padding: 10px;
        border: #{$border-width} solid #{map-get($grays, "300")};
        border-top: none;
        border-radius: 0 0 $input-border-radius $input-border-radius;
    }
}

// Search box
.search-bar {
    .form-control {
        padding-left: 40px;
        padding-right: 20px;
        border-radius: 30px;
    }

    span {
        position: absolute;
        z-index: 10;
        font-size: 16px;
        line-height: $input-height;
        left: 13px;
        top: -2px;
        color: $text-muted;
    }
}

.search-box {
    position: relative;
    .form-control {
        padding-right: 36px;
    }
    .search-icon {
        position: absolute;
        top: 7px;
        right: 12px;
        font-size: 16px;
    }
}

// Show Password
.password-eye {
    &:before {
        font-family: feather !important;
        content: "\e86a";
        font-style: normal;
        font-weight: 400;
        font-variant: normal;
        vertical-align: middle;
        line-height: 1.2;
        font-size: 16px;
    }
}

.show-password {
    .password-eye {
        &:before {
            content: "\e86e";
        }
    }
}

// dark mode
body[data-layout-color="dark"] {
    .comment-area-box {
        .form-control {
            border-color: #{map-get($dark-grays, "300")};
        }
        .comment-area-btn {
            background-color: #{map-get($dark-grays, "100")};
            border: #{$border-width} solid #{map-get($dark-grays, "300")};
        }
    }
}

label.required::before {
    content: "* ";
    color: $danger;
}

#statusSwitch {
    width: 4em;
    height: 2.5em;
    border-radius: 5px;
    background-position: left center;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 5 8'%3e%3crect width='150' height='150' x='-4' y='-4' opacity='0.5' fill='%238b96a0'/%3e%3c/svg%3e");
    transition: background-position .15s ease-in-out;
}

#statusSwitch:checked {
    background-position: right center;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3crect width='150' height='150' x='-1' y='-10' fill='%23fff'/%3e%3c/svg%3e");
}